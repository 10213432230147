<template>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex +  1 }}
    </q-td>

    <q-td
        key="id"
        :props="data"
        auto-width
    >
      <div class="row items-center">
        <h5 class="text-subtitle1 q-ma-none q-mr-sm text-weight-bold">
          {{ data.row.id }}
        </h5>
      </div>

      <br>

      <p class="q-ma-none text-caption">
        {{ $moment((typeof data.row.created === 'object' ? data.row.created.date : data.row.created)).format(appOptions.formats.fullDate) }}
      </p>

    </q-td>

    <q-td
        key="deliveryRequest"
        :props="data"
        auto-width
    >
      {{data.row.deliveryRequests.count}}
    </q-td>

    <q-td
        key="sender"
        :props="data"
        auto-width
    >
      {{'ID: ' + data.row._embedded.sender.id}}
      <br>
      {{data.row._embedded.sender.name}}
    </q-td>


    <q-td
        key="state"
        :props="data"
        auto-width
    >
      <super-dropdown :model="dropdownModel" />
    </q-td>
  </q-tr>
</template>

<script>
import { mapMutations , mapGetters } from 'vuex'

// Components
import SuperDropdown from '../super-dropdown/SuperDropdown.vue'

export default {
  name: 'DeliveryServicesMovementsRow',
  emits: ['check', 'dblclick'],
  components: {
    SuperDropdown
  },
  props: {
    data: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      movementStatuses: [
        {
          buttons: [
            { id: 'new', title: 'New', transitions: ['confirmed', 'checking', 'complete', 'deleted', 'cancelled', 'locked'], color: 'info'},
            { id: 'confirmed', title: 'Confirmed', transitions: ['new', 'checking', 'complete', 'deleted', 'cancelled', 'locked'], color: 'teal'},
            { id: 'checking', title: 'Checking', transitions: ['new', 'confirmed', 'complete', 'deleted', 'cancelled', 'locked'], color: 'amber'},
            { id: 'complete', title: 'Complete', transitions: ['new', 'confirmed', 'checking', 'deleted', 'cancelled', 'locked'], color: 'success'},
            { id: 'deleted', title: 'Deleted', transitions: ['new', 'confirmed', 'checking', 'complete', 'cancelled', 'locked'], color: 'danger' } ,
            { id: 'cancelled', title: 'Cancelled', transitions: ['new', 'confirmed', 'checking', 'complete', 'deleted', 'locked'] , color: 'negative text-white'},
            { id: 'locked', title: 'Locked', transitions: ['new', 'confirmed', 'checking', 'complete', 'deleted', 'cancelled'], color: 'warning'}
          ]
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
    ]),
    dropdownModel () {
      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: this.movementStatuses[0].buttons[1].color,
        editableFields: false,
        disabledLabels: true,
        onClick: (state) => {
          this.data.row.state = state
          return this.$service.movement.save({state: state}, this.data.row.id)
        },
        value: this.movementStatuses[0].buttons[0],
        options: this.movementStatuses
      }

      this.movementStatuses.find(group => {
        let status = group.buttons.find(btn => {
          return btn.id === this.data.row.state
        })

        if (status) {
          option.color = status.color
          option.value = status
        }

        return status
      })

      return option
    },
  },
  methods: {
    ...mapMutations([
    ]),
    onRowDblClick () {
      this.$emit('dblclick', this.data.row)
    },
    handleCheck () {
      this.$emit('check', this.data.row)
    },
  }
}
</script>