<template>
  <q-dialog v-model="isOpen" class="modal-md">
    <q-card style="overflow: visible;">
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('New Carton Forwarding') }}
        </div>

        <q-space />

        <q-btn
            color="transparent"
            text-color="dark"
            size="sm"
            icon="close"
            no-caps
            unelevated
            @click="close"
        />
      </q-card-section>

      <form-builder :schema="schema" />

      <div class="row justify-center border-top q-py-sm">
        <div class="q-mr-sm">
          <q-btn
              color="dark"
              text-color="white"
              size="sm"
              :label="$t('Close')"
              no-caps
              unelevated
              @click="close"
          />
        </div>

        <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            :label="$t('Save')"
            no-caps
            unelevated
            @click="save"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
// Components
import {mapMutations , mapActions} from "vuex";

export default {
  name: 'CartonForwardingModal',
  emits: ['submit'],
  components: {
  },
  data () {
    return {
      isOpen: false,
      options: {},
      model: {
        state: 'new',
        sender: null,
      },
      states: [
        { id: 'new', title: this.$t('New')},
        { id: 'confirmed', title: this.$t('Confirmed')},
        { id: 'complete', title: this.$t('Complete')},
        { id: 'cancelled', title: this.$t('Cancelled')},
        { id: 'deleted', title: this.$t('Deleted')},
        { id: 'locked', title: this.$t('Locked')},
        { id: 'checking' , title: this.$t('Checking')}
      ],
    }
  },
  mounted() {
    this.setMovement({
      state: 'new',
      sender: null,
    })
    this.setNewMovement()
  },
  computed: {
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Sender'),
                field: 'sender',
                wrapperStyleClasses: 'col-12 q-pa-lg',
                value: this.model.sender,
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: sender => {
                  this.model.sender = sender
                  this.updateMovement({sender: sender.id})
                }
              }
            ]
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions([
      'saveMovement'
    ]),
    ...mapMutations([
      'setNewMovement',
      'setMovement',
      'setCleanMovement',
      'updateMovement',
      'upsertMovement',
      'addErrorNotification',
    ]),
    close () {
      this.isOpen = false
    },
    open (options = {}) {
      this.isOpen = true
      this.options = options
    },
    save () {
      if(!this.model.sender) {
        this.addErrorNotification('Please select sender!')
        return
      }
      this.saveMovement(true)
          .then(item=>{
              this.upsertMovement(item)
              this.model= {
                state: 'new',
                sender: null,
              }
          })

      this.close()
    },
  }
}
</script>
